import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";

const StyledWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHeaderBox = styled.div`
  position: absolute;
  width: 100%;
  top: 110px;
  left: 0;
  z-index: 9998;
  background-color: ${({ theme }) => theme.white};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.s};
    top: 120px;
    width: 50%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledHeader = styled.h1`
  color: ${({ theme }) => theme.black};
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 40px;

  ${({ theme }) => theme.mq.desktop} {
    margin: 40px 60px;
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledImage = styled(GatsbyImage)`
  height: 100vh;
  width: 100%;
  opacity: 0.8;

  ${({ theme }) => theme.mq.desktop} {
    margin-left: 250px;
  }
`;

const Hero = ({ image, text }) => (
  <StyledWrapper>
    <StyledHeaderBox>
      <StyledHeader>{text}</StyledHeader>
    </StyledHeaderBox>
    <StyledImage image={image.asset.gatsbyImageData} />
  </StyledWrapper>
);
export default Hero;
