import React from 'react';
import styled from 'styled-components';
import {Link, graphql} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";
import Hero from '../components/Hero';
import HeaderWithLine from '../components/HeaderWithLine';
import SEO from '../components/SEO';
import Offers from '../components/Offers';
import StyledButton from '../components/Button';

const StyledAboutWrapper = styled.div`
  margin: 40px;
  max-width: 1200px;

  ${({theme}) => theme.mq.desktop} {
    margin: 100px auto 100px 250px;
  }
`;

const StyledAboutTitle = styled.h3`
  font-size: ${({theme}) => theme.font.size.s};
  font-weight: 600;
  text-transform: uppercase;

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.m};
  }
`;

const StyledAboutDescription = styled.p`
  font-size: ${({theme}) => theme.font.size.xs};
  line-height: 1.5;

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.s};
  }
`;

const StyledOffersWrapper = styled.div`
  background-color: #f5f5f5;
`;

const StyledTextWithLineOffers = styled(HeaderWithLine)`
  padding-top: 4rem;
`;

const StyledGalleryWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;

const StyledGalleryHeaderBox = styled.div`
  ${({theme}) => theme.mq.desktop} {
    float: left;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 300px;
    width: 50%;
    top: 0;
    left: 0;
    z-index: 9998;
    background-color: ${({theme}) => theme.white};
    font-size: ${({theme}) => theme.font.size.s};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledGalleryImage = styled(GatsbyImage)`
  height: 100vh;
  opacity: 0.8;

  ${({theme}) => theme.mq.desktop} {
    margin-left: 250px;
    margin-top: 0;
  }
`;

const StyledGalleryHeader = styled(HeaderWithLine)`
  padding-top: 2rem;
`;

const StyledGalleryLinks = styled.div`
  margin: 2rem;
  line-height: 1.5;
  font-size: ${({theme}) => theme.font.size.s};
  font-weight: 600;

  ${({theme}) => theme.mq.desktop} {
    margin: 20px 60px;
    font-size: ${({theme}) => theme.font.size.m};
  }
`;

const StyledGalleryLink = styled(Link)`
  text-decoration: underline;
  list-style: none;
  color: ${({theme}) => theme.black};
  position: relative;
`;

export default function HomePage({data}) {
    const galleryMainPhoto = data.singlePage.pageimage.find((image) =>
        image.name.includes('Galeria')
    );

    return (
        <>
            <SEO title="Strona główna"/>
            <Hero image={data.singlePage.image} text={data.singlePage.description}/>
            <StyledAboutWrapper>
                <StyledAboutTitle>
                    PROJEKTOWANIE, REALIZACJA i PIELĘGNACJA ZIELENI
                </StyledAboutTitle>
                <StyledAboutDescription>
                    Serdecznie zapraszamy do zapoznania się z naszą{' '}
                    <Link to="/oferta">ofertą</Link> oraz{' '}
                    <Link to="/kontakt">kontaktu</Link> w celu omówienia szczegółów
                    dotyczących oferowanych przez nas usług. Działamy na głównie na
                    terenie Poznania oraz okolic w obrębie 100km. Ponadto nasze realizacje
                    można spotkać w kilku innych miastach Polski poza obrębem głównego
                    działania.
                </StyledAboutDescription>
                <StyledButton as={Link} to="/onas">
                    Dowiedź się więcej
                </StyledButton>
            </StyledAboutWrapper>
            <StyledOffersWrapper>
                <StyledTextWithLineOffers>NASZA OFERTA</StyledTextWithLineOffers>
                <Offers/>
            </StyledOffersWrapper>
            <StyledGalleryWrapper>
                <StyledGalleryHeaderBox>
                    <StyledGalleryHeader>GALERIA</StyledGalleryHeader>
                    <StyledGalleryLinks>
                        Obejrzyj nasze{' '}
                        <StyledGalleryLink to="/galeria/realizacje">
                            realizacje
                        </StyledGalleryLink>
                        ,{' '}
                        <StyledGalleryLink to="/galeria/pielegnacje">
                            pielęgnacje
                        </StyledGalleryLink>{' '}
                        oraz{' '}
                        <StyledGalleryLink to="/galeria/projekty">
                            projekty
                        </StyledGalleryLink>{' '}
                        ogrodów. Sprawdź jak montujemy{' '}
                        <StyledGalleryLink to="/galeria/systemy-nawadniajace">
                            systemy nawadniające
                        </StyledGalleryLink>
                        . Zajrzyj do naszej{' '}
                        <StyledGalleryLink to="/galeria/">galerii</StyledGalleryLink>!
                    </StyledGalleryLinks>
                </StyledGalleryHeaderBox>
                <StyledLink to="/galeria">
                    <StyledGalleryImage image={galleryMainPhoto.image.asset.gatsbyImageData}/>
                </StyledLink>
            </StyledGalleryWrapper>
        </>
    );
}

export const query = graphql`
  query SinglePageHomeQuery {
    singlePage: sanitySinglepage(name: { eq: "Home" }) {
      id
      name
      description
      image {
        asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      pageimage {
        id
        name
        image {
          asset {
            id
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
    offersIntro: allSanityOffersIntro {
      edges {
        node {
          id
          name
          description
          image {
            asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
