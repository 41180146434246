import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from "gatsby-plugin-image";
import GatsbyLink from 'gatsby-link';

const StyledGallery = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 4rem;
`;

const GalleryItems = styled.div`
  display: grid;
  width: 100rem;
  grid-gap: 2rem;
  align-items: start;
  margin: 50px;

  ${({ theme }) => theme.mq.tablet} {
    margin: 50px 100px;
  }

  ${({ theme }) => theme.mq.desktop} {
    margin: 50px 100px 100px 250px;
    grid-template-columns: repeat(2, minmax(20rem, 50rem));
  }
`;

const GalleryItem = styled(GatsbyLink)`
  position: relative;
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.black};
  text-align: center;
  transition-duration: 0.5s;

  &:hover {
    transition-duration: 0.5s;
    transform: translateY(-0.5%);
    box-shadow: 0 4rem 8rem rgba(0, 0, 0, 0.2);
  }
`;

const StyledImg = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: 25rem;
  object-fit: cover;
  opacity: 0.8;
  transition-duration: 0.5s;

  :hover {
    transition-duration: 0.5s;

    opacity: 1;
  }

  ${GalleryItem}:hover & {
    opacity: 1;
  }
`;

const Content = styled.div`
  padding: 1.5rem;
  text-align: center;
`;

export default function Offers() {
  const offerPhotos = useStaticQuery(graphql`{
  files: allFile(
    filter: {relativePath: {regex: "/oferta/"}}
    sort: {fields: name}
  ) {
    edges {
      node {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`);

  const offerTitles = [
    'Projektowanie ogrodów',
    'Urządzanie ogrodów',
    'Pielęgnacja ogrodów',
    'Wynajem koparko-ładowarki',
  ];

  const offerSection = ['projektowanie', 'realizacja', 'pielegnacje', 'avant'];

  return <>
    <StyledGallery>
      <GalleryItems>
        {offerPhotos.files.edges.map((node, i) => (
          <GalleryItem to={`/oferta#${offerSection[i]}`}>
            <StyledImg
              image={node.node.childImageSharp.gatsbyImageData}
              alt={node.node.id}
            />
            <Content>
              <h3>{offerTitles[i]}</h3>
            </Content>
          </GalleryItem>
        ))}
      </GalleryItems>
    </StyledGallery>
  </>;
}
